<template>
    <div class="properties_block">
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Type</label>
            <div>Text Input</div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Name</label>
            <div><input type="text" class="px-2" v-model="property_form_data.name" placeholder="Enter Name"
                    @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Label</label>
            <div><input type="text" class="px-2" v-model="property_form_data.label" placeholder="Enter Label"
                    @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Placeholder</label>
            <div><input type="text" class="px-2" v-model="property_form_data.placeholder"
                    placeholder="Enter Placeholder" @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Maxlength</label>
            <div><input type="number" class="pl-2" v-model="property_form_data.maxlength" placeholder="e.g. 30" min="1"
                    @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Prefix</label>
            <div><input type="text" class="px-2" v-model="property_form_data.prefix" placeholder="Enter prefix"
                    @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Suffix</label>
            <div><input type="text" class="px-2" v-model="property_form_data.suffix" placeholder="Enter suffix"
                    @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Required</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.required"
                    @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Regular Exp.<br><small>Visit <a href="https://regexr.com/" target="_blank"
                        class=" text-indigo-600 hover:text-indigo-700">regexr.com</a></small></label>
            <div><input class="px-2" type="text" v-model="property_form_data.reg_exp" placeholder="i.e. ([0-9]{4})"
                    @input="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For Certificate <sup v-if="certificate_data[property_form_data.name]">*</sup></label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked"
                    v-model="property_form_data.for_certificate" @change="add_properties" />
            </div>
        </div>
        <div v-if="qr_data" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For QR Data <sup v-if="qr_data[property_form_data.name]">*</sup></label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_qr"
                    @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For Readonly</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_readonly"
                    @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Check Valid Type</label>
            <div><select v-model="property_form_data.check_valid_type" @change="add_properties">
                    <option value="">Choose One</option>
                    <option v-for="(field_type, field_value) in field_validation_types" :value="field_value">{{
                        field_type }}</option>
                </select></div>
        </div>
        <div v-if="property_form_data.check_valid_type == 'email'"
            class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Send E-mail</label>
            <!-- {{ property_form_data.send_email }} -->
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.send_email"
                    @change="add_properties" />
            </div>
        </div>
        <div v-if="property_form_data.send_email"
            class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <!-- {{ property_form_data.check_valid_type }} -->
            <label>Mail For</label>
            <div><select v-model="property_form_data.mail_for" @change="add_properties">
                    <option v-for="(field_type, field_value) in mail_for_types" :value="field_value">{{ field_type }}
                    </option>
                </select></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Same as</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.same_as"
                    @change="add_properties" />
            </div>
        </div>
        <template v-if="property_form_data.same_as">
            <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Same as Label</label>
                <div><input type="text" class="px-2" v-model="property_form_data.same_as_label"
                        placeholder="Enter Label" @keyup="add_properties" @change="add_properties" /></div>
            </div>
            <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Same as Input Field</label>
                <div><input type="text" class="px-2" v-model="property_form_data.same_as_col_name"
                        placeholder="Enter Input Field" @keyup="add_properties" @change="add_properties" /></div>
            </div>
        </template>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Project Remaining Value</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked"
                    v-model="property_form_data.project_remaining_value" @change="add_properties" />
            </div>
        </div>
        <!-- <pre>{{ data }}</pre> -->
        <!-- <pre>{{ qr_data }}</pre> -->
    </div>
</template>
<script setup>
import { ref, watch } from 'vue'
const props = defineProps({
    data: Object,
    certificate_data: Object,
    qr_data: Object,
    field_validation_types: Object,
})
const emit = defineEmits(['addProperties', 'modifyCertificateData', 'modifyQrData', 'removeCertificateData', 'removeQrData'])
const mail_for_types = ref({
    1: 'Just for notify',
    2: 'For Approval with Signature'
})
const name_key_press = ref(false)
const property_form_data = ref({
    name: props.data && props.data.name ? props.data.name : '',
    type: props.data && props.data.type ? props.data.type : 'txt_input',
    value: props.data && props.data.value ? props.data.value : '',
    label: props.data && props.data.label ? props.data.label : '',
    placeholder: props.data && props.data.placeholder ? props.data.placeholder : '',
    maxlength: props.data && props.data.maxlength ? props.data.maxlength : '',
    prefix: props.data && props.data.prefix ? props.data.prefix : '',
    suffix: props.data && props.data.suffix ? props.data.suffix : '',
    required: props.data && props.data.required ? props.data.required : false,
    reg_exp: props.data && props.data.reg_exp ? props.data.reg_exp : '',
    for_certificate: props.data && props.data.for_certificate ? props.data.for_certificate : false,
    for_qr: props.data && props.data.for_qr ? props.data.for_qr : false,
    for_readonly: props.data && props.data.for_readonly ? props.data.for_readonly : false,
    check_valid_type: props.data && props.data.check_valid_type ? props.data.check_valid_type : '',
    send_email: props.data && props.data.send_email ? props.data.send_email : false,
    mail_for: props.data && props.data.mail_for ? props.data.mail_for : '',
    same_as: props.data && props.data.same_as ? props.data.same_as : false,
    same_as_checked: props.data && props.data.same_as_checked ? props.data.same_as_checked : false,
    same_as_label: props.data && props.data.same_as_label ? props.data.same_as_label : '',
    same_as_col_name: props.data && props.data.same_as_col_name ? props.data.same_as_col_name : '',
    project_remaining_value: props.data && props.data.project_remaining_value ? props.data.project_remaining_value : false
})

watch(() => props.data, (updateData, oldValue) => {
    // //console.log(updateData, oldValue)
    name_key_press.value = false
    property_form_data.value = {
        name: updateData && updateData.name ? updateData.name : '',
        type: updateData && updateData.type ? updateData.type : 'txt_input',
        value: updateData && updateData.value ? updateData.value : '',
        label: updateData && updateData.label ? updateData.label : '',
        placeholder: updateData && updateData.placeholder ? updateData.placeholder : '',
        maxlength: updateData && updateData.maxlength ? updateData.maxlength : '',
        prefix: updateData && updateData.prefix ? updateData.prefix : '',
        suffix: updateData && updateData.suffix ? updateData.suffix : '',
        required: updateData && updateData.required ? updateData.required : false,
        reg_exp: updateData && updateData.reg_exp ? updateData.reg_exp : '',
        for_certificate: updateData && updateData.for_certificate ? updateData.for_certificate : false,
        for_qr: updateData && updateData.for_qr ? updateData.for_qr : false,
        for_readonly: updateData && updateData.for_readonly ? updateData.for_readonly : false,
        check_valid_type: updateData && updateData.check_valid_type ? updateData.check_valid_type : '',
        send_email: props.data && props.data.send_email ? props.data.send_email : false,
        mail_for: props.data && props.data.mail_for ? props.data.mail_for : '',
        same_as: updateData && updateData.same_as ? updateData.same_as : false,
        same_as_checked: updateData && updateData.same_as_checked ? updateData.same_as_checked : false,
        same_as_label: updateData && updateData.same_as_label ? updateData.same_as_label : '',
        same_as_col_name: updateData && updateData.same_as_col_name ? updateData.same_as_col_name : '',
        project_remaining_value: updateData && updateData.project_remaining_value ? updateData.project_remaining_value : false
    }
})

watch(() => property_form_data.value.name, (updateName, oldName) => {
    // //console.log(updateName, oldName)
    if (updateName !== oldName && name_key_press.value) {
        let col_name_old = oldName.replace(/\s/g, '_').toLowerCase()
        emit('removeCertificateData', col_name_old)
        emit('removeQrData', col_name_old)

        let col_name_new = updateName.replace(/\s/g, '_').toLowerCase()
        emit('modifyCertificateData', col_name_new)
        emit('modifyQrData', col_name_new)
    }
})

watch(() => property_form_data.value.for_certificate, (updateData, oldData) => {
    //console.log(updateData, oldData)
    /**
     * Certificate data push
     */
    let col_name = property_form_data.value.name.replace(/\s/g, '_').toLowerCase()
    if (updateData) emit('modifyCertificateData', col_name)
    else emit('removeCertificateData', col_name)
})

watch(() => property_form_data.value.for_qr, (updateData, oldData) => {
    //console.log(updateData, oldData)
    /**
     * QR data push
     */
    let col_name = property_form_data.value.name.replace(/\s/g, '_').toLowerCase()
    if (updateData) emit('modifyQrData', col_name)
    else emit('removeQrData', col_name)
})

const add_properties = () => {
    name_key_press.value = true
    emit('addProperties', property_form_data.value)
}
</script>
<style lang="scss" scoped>
.properties_block {
    font-size: 12px;

    input,
    select {
        width: 100%;
        height: 26px;
        border: 1px solid #ddd;
        border-radius: 5px;

        &.switch_btn {
            width: 50px;
            height: 26px;
            border-radius: 25px;
            margin-left: -30px;
        }
    }
}
</style>
